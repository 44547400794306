module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-remark-images-virtual-3fd636c5a0/0/cache/gatsby-remark-images-npm-6.21.0-992dc4dafd-abadd26da4.zip/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-remark-autolink-headers-virtual-abb61120dc/0/cache/gatsby-remark-autolink-headers-npm-5.21.0-839f505ecc-e5d480017e.zip/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-bf08be9977/0/cache/gatsby-plugin-manifest-npm-4.21.0-cfd542150c-ce929d5dd0.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Luiz Ipsum","short_name":"Luiz Ipsum","start_url":"/","display":"minimal-ui","icon":"src/assets/images/logo-512px.png","background_color":"#ffffff","theme_color":"#4b334c","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"38bf1ee943a03d75effdc91c6c0163aa"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-analytics-virtual-ffac6d8003/0/cache/gatsby-plugin-google-analytics-npm-4.21.0-c61f2132dc-3894c7a267.zip/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-146759812-1","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-styled-components-virtual-9b8f48da2f/0/cache/gatsby-plugin-styled-components-npm-5.21.0-766d59a64a-31f8d7f44e.zip/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-catch-links-virtual-cdb363cb17/0/cache/gatsby-plugin-catch-links-npm-4.21.0-5c8f564f3e-b4c6fa4b33.zip/node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-5813e72359/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
